// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-palavra-js": () => import("../src/templates/palavra.js" /* webpackChunkName: "component---src-templates-palavra-js" */),
  "component---src-templates-letra-js": () => import("../src/templates/letra.js" /* webpackChunkName: "component---src-templates-letra-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bibliografia-js": () => import("../src/pages/bibliografia.js" /* webpackChunkName: "component---src-pages-bibliografia-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

